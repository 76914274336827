import React, { useState } from "react"

import Layout from "../components/Layout"
import BannerImage from "../components/AcfPageBlocks/BannerImage"
import NavWithHeaderAndText from "../components/AcfPageBlocks/NavWithHeaderAndText"
import TextBoxAndVidOrImg  from "../components/AcfPageBlocks/TextBoxAndVidOrImg"
import { BgImage } from "gbimage-bridge"
import { Col, Container, Row } from "react-bootstrap"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Link from "../components/UI/Link"
import { imageClass, loadMoreBtn } from "./fundraisers.js.module.scss"

const Stories = ({ pageContext, location, history }) => {

  const {fundraisersBannerData, fundraisersNavData, fundraisersFeatureData, bkgdImage} = pageContext
  const {itemsPerPage, allPosts, posts, totalElements, page, nextPagePath} = pageContext

  const [postsToShow, setPostsToShow] = useState(posts)
  const [loadMoreEnable, setLoadMoreEnable] = useState(page === 1 && itemsPerPage < totalElements)

  const loadMorePosts = event => {
    event.preventDefault()
    const size = postsToShow.length + itemsPerPage;
    setPostsToShow([...allPosts.slice(0, size)])
    setLoadMoreEnable(page === 1 && size < totalElements)
  }

  return (
    <>
      <Layout
        seo={pageContext.seo}
        paginationComponent={{
          nextLink: pageContext.nextPagePath,
          prevLink: pageContext.prevPagePath,
          currentLink: pageContext.currentPagePath
        }} >
        <BannerImage {...fundraisersBannerData} />
        <NavWithHeaderAndText
          {...fundraisersNavData}
          location={location}
          crumbLabel={"Fundraisers"}
          history={history}
          pageContext={pageContext}
        />
        <main>
          <TextBoxAndVidOrImg {...fundraisersFeatureData} />
          <div className="bg-pale-grey position-relative h-100 p-0">
            <BgImage image={bkgdImage} style={{ backgroundRepeat: "repeat-y" }}>
              <Container>
                <div
                  className="position-relative h-100 px-0 py-6">
                  <Row>
                    {postsToShow.map(post => {
                      const { title, id, slug, storiesLayouts: { storyObject }} = post

                      if (storyObject && storyObject.length) {
                        const { mainImage } = storyObject[0]
                        const mainImg = getImage(mainImage?.localFile)

                        return (
                          <Col
                            className="d-flex flex-column mb-4"
                            sm={12}
                            md={6}
                            lg={4}
                            key={id}>
                            <GatsbyImage
                              className={imageClass}
                              image={mainImg}
                              alt={mainImage?.altText}
                            />
                            <Link
                              classes="linkClass mt-3"
                              link={`/stories/${slug}`}
                              title={title}
                            />
                          </Col>
                        )
                      }
                    })}
                  </Row>
                </div>
                {loadMoreEnable && page ===1 && (
                  <div className="d-flex flex-column justify-content-center align-items-center pt-5 pb-6">
                    <a className={loadMoreBtn} onClick={loadMorePosts} href={nextPagePath}>Load More</a>
                  </div>
                )}
                {page !== 1 && nextPagePath && (
                  <div className="d-flex justify-content-center pt-5 pb-6">
                    <a className={loadMoreBtn} href={nextPagePath}>Load More</a>
                  </div>
                )}
              </Container>
            </BgImage>
          </div>
        </main>
      </Layout>
    </>
  )
}

export default Stories
